.congrats-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.congrats-container .congrats {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.congrats-container .congrats.back {
  position: relative;
  width: 100000px;
  height: 100%;
  gap: 0;
}

.congrats-container .congrats.back > div {
  height: 100%;
}

.congrats-container .conf {
  max-height: 100%;
  width: calc(100vh);
}
