.meeting .conversation .transcription {
  bottom: 0;
  right: 0;
  position: absolute;
  width: 447px;
  height: 100%;
  max-width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.5rem;
}

.meeting .conversation .transcription-show {
  flex: 1;
  width: 100%;
  max-width: 100%;
  border-radius: 20px;
  background-color: var(--trans-dark-grey);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--off-white-base);
  gap: 1rem;
  z-index: 10;
}

.meeting .conversation .transcription-head {
  font-family: Figtree;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: center;
  width: 100%;
  opacity: 50%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 1rem;
  align-items: center;
  cursor: pointer;
}

.meeting .conversation .transcription-action {
  width: 300px;
  flex: 0;
  font-family: Figtree;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: center;
  opacity: 50%;
  border-radius: 40px;
  padding: 0.75rem 1rem 0.75rem 3rem;
  background-color: var(--trans-dark-grey);
  max-width: 447px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.meeting .conversation .transcription.active .transcription-action {
  border: 2px solid #99afff;
}

.meeting .conversation .transcription-body {
  flex: 1;
  width: 100%;
  font-family: Figtree;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 70%;
  position: relative;
  overflow-y: auto;
}

.meeting .transcription .message {
  text-indent: -2ch;
  margin-left: 2ch;
  overflow-wrap: anywhere;
  text-align: left;
  margin-bottom: 5px;
}

.meeting .transcription .transcription-history {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media screen and (max-width: 1440px) {
  .meeting .conversation .transcription {
    width: 400px;
  }

  .meeting .conversation .transcription-head {
    font-size: 20px;
    line-height: 24px;
  }

  .meeting .conversation .transcription-action {
    font-size: 20px;
    line-height: 24px;
  }

  .meeting .conversation .transcription-body {
    font-size: 18px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .meeting .conversation .transcription {
    width: 300px;
    padding: 1rem;
    gap: 0.4rem;
  }

  .meeting .conversation .transcription-head {
    font-size: 16px;
    line-height: 20px;
  }

  .meeting .conversation .transcription-action {
    font-size: 16px;
    line-height: 20px;
    width: 200px;
    padding-left: 1.5rem;
  }

  .meeting .conversation .transcription-body {
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (max-width: 768px) {
  .meeting .transcription-container {
    min-height: 34px;
  }

  .meeting .conversation .transcription-body {
    display: none;
  }

  .meeting .conversation .transcription {
    padding: 10px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: width 0.5s ease-out, height 0.5s ease-out;
  }

  .meeting .conversation .transcription-head {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
  }

  .meeting .conversation .transcription-action {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
  }

  .meeting .conversation .transcription.active {
    width: 100%;
  }

  .meeting .conversation .transcription.active .transcription-body {
    display: flex;
  }
}

@media screen and (max-height: 770px) {
  .meeting .conversation .transcription-head {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
  }

  .meeting .conversation .transcription-action {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
  }

  .meeting .conversation .transcription {
    padding: 10px;
    height: 100%;
    border-radius: 10px;
    transition: width 0.5s ease-out, height 0.5s ease-out;
  }
}

/* @media screen and (max-width : 425px) {
    
  } */
