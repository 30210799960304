.meeting .conversation .interview-tips {
  bottom: 0;
  left: 10px;
  position: absolute;
  width: 460px;
  max-height: 100%;
  max-width: 100%;
  padding: 1rem;
  display: flex;
  bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0.5rem;
}

.meeting .conversation .interview-tips-body li {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
}

.meeting .conversation .interview-tips-action {
  color: var(--White, #fff);
  font-family: Figtree;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 133.333% */
  letter-spacing: -0.3px;
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 10px 0;
  cursor: pointer;
}

.meeting .conversation .interview-tips-action > span{
  text-wrap: nowrap;
}

.meeting .conversation .interview-tips-action img{
  margin-bottom: 10px;
}

.meeting .conversation .interview-tips-blue {
  color: var(--Blue-400, #53B1FD);
}

.meeting .conversation .interview-tips-body {
  width: 100%;
  font-family: Figtree;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: 40px;
  overflow-y: auto;
}

.meeting .conversation .interview-tips-body::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width */
  height: 12px; /* Adjust scrollbar height */
  background-color: #0000;
}

.meeting .conversation .interview-tips-body::-webkit-scrollbar-thumb {
  background-color: #1a1a1a; /* Set thumb color */
  border-radius: 10px; /* Set border radius for the scrollbar thumb */
  cursor: pointer;
}

.meeting .conversation .interview-tips-body::-webkit-scrollbar-button {
  display: none;
}

@media screen and (max-width: 1440px) {
  .meeting .conversation .interview-tips {
    width: 400px;
  }

  .meeting .conversation .interview-tips-action {
    font-size: 20px;
    line-height: 24px;
  }

  .meeting .conversation .interview-tips-body {
    font-size: 18px;
    line-height: 20px;
  }

  .meeting .conversation .interview-tips-action img{
    height: auto;
    width: 14px;
  }

  .meeting .conversation .interview-tips-body li {
    padding: 5px 0;
    gap: 10px;
  }

  .meeting .conversation .interview-tips-body {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .meeting .conversation .interview-tips {
    width: 260px;
    padding: 1rem;
    gap: 0.4rem;
  }

  .meeting .conversation .interview-tips-action {
    font-size: 16px;
    line-height: 20px;
    width: 200px;
  }

  .meeting .conversation .interview-tips-body {
    font-size: 14px;
    line-height: 16px;
  }
  .meeting .conversation .interview-tips-action img{
    height: auto;
    width: 10px;
  }
  .meeting .conversation .interview-tips-body li {
    padding: 5px 0;
    gap: 10px;
  }
  .meeting .conversation .interview-tips-body {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .meeting .interview-tips-container {
    min-height: 34px;
    display: none;
  }

  .meeting .conversation .interview-tips {
    padding: 10px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: width 0.5s ease-out, height 0.5s ease-out;
  }

  .meeting .conversation .interview-tips-action {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
  }

  .meeting .conversation .interview-tips.active {
    width: 100%;
  }

  .meeting .conversation .interview-tips.active .interview-tips-body {
    display: flex;
  }

  .meeting .conversation .interview-tips-action img{
    height: auto;
    width: 10px;
  }
  .meeting .conversation .interview-tips-body {
    margin-top: 30px;
  }
}

/* @media screen and (max-width : 425px) {
    
  } */
