.meeting {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: var(--black-bg);
  display: flex;
  flex-direction: column;
}

.meeting * {
  color: white;
}

.meeting .control-bar {
  bottom: 0;
  min-height: 150px;
  width: 100%;
  background-color: black;
  position: relative;
}

.meeting .close {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 1;
}

.meeting .main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  flex: 1;
}

.meeting .main.finished {
  position: relative;
}

.meeting .main .names {
  font-family: Figtree;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
}

.meeting .main .topic {
  font-family: Figtree;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
}

.meeting .conversation {
  flex: 1;
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  position: relative;
}

.meeting .conversation .room {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4rem;
}

.meeting .room .person {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
}

.meeting .person .avatar {
  width: 250px;
  height: auto;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.meeting .person .avatar.no-round {
  border-radius: 0;
}

.meeting .person .status {
  font-family: Figtree;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--off-white-base);
}

.meeting .speak-animation {
  position: relative;
}

.meeting .speak-animation .group {
  display: flex;
  gap: 1px;
  align-items: center;
}

.meeting .speak-animation .line {
  border-width: 7px;
  border-color: var(--gray-dark);
  border-style: solid;
  border-radius: 10px;
}

.meeting .speak-animation .fore .line {
  border-color: #00ee34;
}

.meeting .speak-animation .line:nth-child(odd) {
  height: 30px;
}

.meeting .speak-animation .line:nth-child(even) {
  height: 40px;
}

.meeting .speak-animation .mask {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 55%;
}

.meeting .controls-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.meeting .control {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.meeting .controls-container.speak {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.meeting .control-icon {
  font-size: 50px;
  fill: #8a8a8a;
}

.meeting .control .icon {
  width: 30px;
  height: auto;
}

.meeting .control-text {
  font-family: Figtree;
  font-size: 17px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--off-white-base);
}

.meeting .audio-panel {
  background-color: #1f1f1f;
  border-radius: 10px;
  height: 60%;
  display: flex;
  align-items: center;
  padding: 20px 20px 0 5px;
  gap: 10px;
}

.meeting .audio-play {
  position: relative;
}

.meeting .audio-play .group {
  display: flex;
  gap: 5px;
  align-items: center;
}

.meeting .audio-play .line {
  border-width: 5px;
  border-color: var(--off-white-base);
  border-style: solid;
  border-radius: 10px;
}

.meeting .audio-play .fore .line {
  border-color: #00ee34;
}

.meeting .audio-play .line.line1 {
  height: 20px;
}

.meeting .audio-play .line.line2 {
  height: 25px;
}

.meeting .audio-play .line.line3 {
  height: 30px;
}

.meeting .audio-play .line.line4 {
  height: 35px;
}

.meeting .audio-play .line.line5 {
  height: 40px;
}

.meeting .audio-play .mask {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 55%;
}

.meeting .audio-panel .audio-control {
  padding: 0 0 30px;
}

.meeting .audio-panel .control-icon {
  font-size: 70px;
  cursor: pointer;
}

.meeting .control-bar .audio-status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.meeting .control-bar .show-time {
  display: flex;
  justify-content: space-between;
}

.meeting .control-bar .show-time > div {
  font-family: Figtree;
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--off-white-base);
}

.meeting .controls-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.meeting .controls-end .button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-family: Figtree;
  font-size: 28px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--off-white-base);
}

.meeting .controls-end .icon {
  width: 26px;
  height: auto;
}

.meeting .control-bar .button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-family: Figtree;
  font-size: 28px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--off-white-base);
}

.meeting .controls-bar .icon {
  width: 26px;
  height: auto;
}

.meeting .main .mobile {
  display: none;
}

.meeting .control-bar .mobile {
  display: none;
}

.meeting .mobile {
  display: none;
}

.meeting .mainBoard {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background-image: url("assets/images/meeting/meeting-back.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.meeting .fill-board {
  position: absolute;
  width: 100%;
  height: 100%;
}

.meeting .controls-end.special .button:first-child {
  display: none;
}

@media screen and (max-width: 1440px) {
  .meeting .conversation .room {
    gap: 3rem;
  }

  .meeting .person .avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .meeting .main .names {
    font-size: 28px;
    line-height: 34px;
  }

  .meeting .main .topic {
    font-size: 28px;
    line-height: 34px;
  }

  .meeting .control-bar {
    min-height: 100px;
  }

  .meeting .controls-end .button {
    font-size: 16px;
    line-height: 20px;
  }

  .meeting .controls-end .icon {
    width: 16px;
  }

  .meeting .audio-panel {
    height: 80%;
    padding: 20px 20px 0 5px;
    gap: 10px;
  }

  .meeting .audio-play .line {
    border-width: 3px;
    border-radius: 10px;
  }

  .meeting .audio-panel .control-icon {
    font-size: 40px;
  }

  .meeting .control-icon {
    font-size: 30px;
    fill: #8a8a8a;
  }

  .meeting .control-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .meeting .room .person {
    gap: 0;
  }

  .meeting .conversation .room {
    gap: 2rem;
  }

  .meeting .speak-animation .line {
    border-width: 5px;
    border-radius: 10px;
  }

  .meeting .person .avatar {
    width: 150px;
    height: 150px;
  }

  .meeting .main .names {
    font-size: 20px;
    line-height: 24px;
  }

  .meeting .main .topic {
    font-size: 20px;
    line-height: 24px;
  }

  .meeting .control-bar {
    min-height: 100px;
  }

  .meeting .speak-animation .line:nth-child(odd) {
    height: 20px;
  }

  .meeting .speak-animation .line:nth-child(even) {
    height: 30px;
  }
}

@media screen and (max-width: 900px) {
  .meeting .controls-end.special {
    left: 0;
    right: auto;
  }
  .meeting .controls-end:not(.special) .button:first-child {
    display: none;
  }
  .meeting .controls-end.special .button:first-child {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .meeting .controls-end.special .button:first-child {
    display: none;
  }
  .meeting .control-bar .button {
    font-size: 16px;
    line-height: 20px;
  }

  .meeting .controls-container.speak {
    justify-content: space-around;
    gap: 0;
    padding: 10px;
  }

  .meeting .close {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 15px;
  }

  .meeting .room .person {
    display: none;
    gap: 0;
  }

  .meeting .room .person.active {
    display: flex;
  }

  .meeting .room {
    width: 100%;
  }

  .meeting .conversation {
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    gap: 1rem;
    position: static;
  }

  .meeting .audio-panel {
    max-width: calc(100% - 100px);
    height: 100%;
    padding: 20px 20px 0 5px;
    gap: 5px;
  }

  .meeting .mainBoard {
    background-position: center right;
    background-size: cover;
  }

  .meeting .main .mobile {
    display: block;
  }

  .meeting .control-bar .mobile {
    display: flex;
  }

  .meeting .control.mobile {
    display: flex;
  }

  .meeting .desktop {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .meeting .audio-play .group {
    gap: 1.5px;
  }

  .meeting .audio-play .line {
    border-width: 3px;
    border-radius: 10px;
  }
}

@media screen and (max-height: 770px) {
  .meeting .person .avatar {
    width: 120px;
    height: 120px;
  }

  .meeting .control-bar .button {
    font-size: 16px;
    line-height: 20px;
  }

  .meeting .controls-container.speak {
    gap: 1rem;
    padding: 10px;
  }

  .meeting .close {
    padding: 20px 20px 15px;
    right: 10px;
    top: 10px;
  }

  .meeting .conversation {
    padding: 6px;
    gap: 0.5rem;
  }

  .meeting .audio-panel {
    max-width: calc(100% - 100px);
    height: 100%;
    padding: 10px 10px 0 5px;
    gap: 5px;
  }

  .meeting .main .names {
    font-size: 20px;
    line-height: 24px;
  }

  .meeting .main .topic {
    font-size: 20px;
    line-height: 24px;
  }

  .meeting .control-bar {
    min-height: 100px;
  }
}
