.meeting .progress {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meeting .progress .mask {
  overflow-x: hidden;
  width: 18%;
  /* width: 51%;  */
  /* width: 84%;  */
  /* width: 100%;  */
  position: absolute;
  top: 0;
}

.meeting .progress .step2 .mask {
  width: 51%;
}

.meeting .progress .step3 .mask {
  width: 84%;
}

.meeting .progress .step4 .mask {
  width: 100%;
}

.meeting .progress .progress-bar {
  position: relative;
}

.meeting .progress .bar {
  width: 50vw;
  min-width: 500px;
  max-width: 833px;
}

.meeting .progress .clap{
    position: absolute;
    width: 100px;
    z-index: 10;
    top: -20px;
}

.meeting .progress .clap.first{
    left: calc(35% - 50px);
}

.meeting .progress .clap.second{
    left: calc(68% - 50px);
}

@media screen and (max-width: 1440px) {
  .meeting .progress {
    height: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .meeting .progress {
    height: 70px;
  }
}

@media screen and (max-width: 768px) {
  .meeting .progress {
    position: relative;
    left: 50vw;
  }
  
  .meeting .progress .progress-bar {
    position: absolute;
    left: -3%;
  }
  
  .meeting .progress .progress-bar.step2 {
    left: -190px;
  }
  
  .meeting .progress .progress-bar.step3 {
    left: -345px;
  }
  
  .meeting .progress .progress-bar.step4 {
    left: -500px;
  }
}

@media screen and (max-height: 770px) {
  .meeting .progress {
    height: 70px;
  }
}