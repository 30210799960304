.meeting .fill-board .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
}

.meeting .fill-board {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
}

.meeting .fill-board .start-button {
    width: 50vmin;
    height: 50vmin;
    background: linear-gradient(199.34deg, rgba(25, 25, 25, 0.4) 30.11%, rgba(50, 50, 50, 0.77) 82.93%);
    border: 3px solid var(--light-linkedin-grey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2%;
    cursor: pointer;
    box-shadow: 0px 4px 164px 0px #050711;
}

.meeting .fill-board .start-button:hover {
    box-shadow: none;
}

.meeting .fill-board .start-button img {
    width: 40%;
    height: auto;
    
}

@media screen and (max-width: 768px) {
    .meeting .fill-board .close-button {
        top: 20px;
        right: 20px;
    }
}