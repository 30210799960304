.meeting .control {
  position: relative;
}

.meeting .helper {
  z-index: 20;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;
  background-color: #091a9f;
  color: #e6ddff;
  border-radius: 30px 30px 30px 0;
  box-shadow: 0px 6px 40px 10px #99afff1a;
  padding: 20px;
  width: 250px;
  font-family: "HK Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .meeting .helper {
    position: absolute;
    bottom: calc(100% + 10px);
    left: auto;
    right: 0;
    background-color: #091a9f;
    color: #e6ddff;
    border-radius: 30px 30px 0 30px;
    padding: 20px;
    width: 250px;
  }
}
