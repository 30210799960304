.meeting .wave-form {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 5px;
  visibility: hidden;
}

.meeting .wave-form.active {
  visibility: visible;
}

.meeting .wave {
  height: 90px;
}

.meeting .speed {
  cursor: pointer;
  font-family: Figtree, serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  z-index: 1;
}

.meeting .speed.hide {
  visibility: hidden;
  cursor: default;
}

@media screen and (max-height: 770px) {
  .meeting .wave {
    height: 70px;
  }
  .meeting .wave-form {
    margin: -8px 0 -12px;
  }
}
