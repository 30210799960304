.tooltip-container {
  position: relative;
  display: flex;
  align-items: center;
}
.tooltip-body {
  position: absolute;
  right: -8px;
  bottom: 100%;
  display: none;
  align-items: flex-end;
  flex-direction: column;
  gap: 0;
}

.tooltip-container:hover .tooltip-body {
  display: flex;
}

.tooltip-body span {
  color: var(--Gray-700, #344054) !important;
  text-align: center;
  /* Text xs/Semibold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  border-radius: 8px;
  background: var(--White, #fff) !important;
  padding: 8px 12px;
  text-wrap: nowrap;
  max-width: calc(100vw - 20px);
}

.tooltip-body img {
  transform: translateY(-1px);
}

@media screen and (max-width: 768px) {
  .tooltip-body span {
    width: 400px;
    text-wrap: wrap;
  }
}

@media screen and (max-width: 425px) {
  .tooltip-body span {
    width: 300px;
    text-wrap: wrap;
  }
}
